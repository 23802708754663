/**
 * @generated SignedSource<<54796b51449ef5dca92f5eba012b0521>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroMultipleOffers_contentBlock$data = {
  readonly offers: ReadonlyArray<{
    readonly buttonText: string;
    readonly description: string | null;
    readonly programExternalId: string;
  } | null>;
  readonly " $fragmentType": "HeroMultipleOffers_contentBlock";
};
export type HeroMultipleOffers_contentBlock$key = {
  readonly " $data"?: HeroMultipleOffers_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroMultipleOffers_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroMultipleOffers_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MultiChoiceBonusType",
      "kind": "LinkedField",
      "name": "offers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programExternalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buttonText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HeroMultipleOffersBlock",
  "abstractKey": null
};

(node as any).hash = "85e4feb2e69118836e95e266eb66bec7";

export default node;
